import TablesController from './tables_controller'

export default class extends TablesController
  @targets: ['headToggle', 'rowToggle', 'openRows', 'root', 'tree']

  # Initial row toggling
  headToggleTargetConnected: -> @toggleOpenRows()

  # Toggle rows according to record
  toggleOpenRows: ->
    openRowsIds = $(@openRowsTarget).val().split('_').filter (string) -> string.length
    openRowsIds.forEach (id) => @toggleRows $("#row_#{id}")[0], true, false
    @toggleHeaderRow()
    @reshadeRows @treeTarget

  # Reset the open row record
  resetOpenRows: -> $(@openRowsTarget).val('') if @hasOpenRowsTarget

  # Whole tree toggling for folders, standard categories, & reports
  toggleTree: (event) ->
    open = not $(event.target).attr('open')
    root_id = if @hasRootTarget then $(@rootTarget).val() else ''
    $subtrees = $(@rowToggleTargets).filter "[data-parent='row_#{root_id}']"
    $subtrees.each (_, row) => @toggleRows row, open, true
    @toggleHeaderRow()
    @reshadeRows @treeTarget

  # Local subtree toggling for folders, standard categories, & reports
  toggleSubtree: (event) ->
    open = not $(event.target).attr('open')
    @toggleRows $(event.target).closest('.tr')[0], open, false
    @toggleHeaderRow()
    @reshadeRows @treeTarget

  # Toggle rows under given row
  toggleRows: (currentRow, open, recursive) ->
    $childrenRows = $(@rowToggleTargets).filter("[data-parent='#{currentRow.id}']")
    $childrenRows.each -> $(@).toggleClass('hide', !open)
    if !open or recursive
      $subtreeRows = $childrenRows.has(':not(.empty)')
      $subtreeRows.each (_, row) => @toggleRows row, open, recursive
    @toggleArrows currentRow, open
    @recordOpenRow currentRow, open

  # Toggle header row
  toggleHeaderRow: -> @toggleArrows @headToggleTarget, $(@rowToggleTargets).filter('.hide').length is 0

  # Toggle arrows on rows
  toggleArrows: (row, open) -> $(row).find('.file, .tree').attr 'open', open

  # Record open rows
  recordOpenRow: (row, open) ->
    id = "_#{row.id.substring(4)}_"
    oldOpenRows = $(@openRowsTarget).val()
    present = oldOpenRows.includes id
    newOpenRows = if open and not present then "#{oldOpenRows}#{id}"
    else if not open and present then oldOpenRows.split(id).join('')
    else oldOpenRows
    $(@openRowsTarget).val newOpenRows
