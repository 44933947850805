import TablesController from './tables_controller'

export default class extends TablesController
  @targets: ['package', 'problemSet', 'school', 'course', 'projects', 'form', 'controls', 'search', 'table', 'report', 'button']

  # Store initial value of projects selector
  initialize: ->
    $(@projectsTarget).prop 'previous', $(@projectsTarget).val() if @hasProjectsTarget

  # Initial report control updating
  connect: -> @updateControls()

  # Initial filtering
  tableTargetConnected: -> @filterTable()

  textInParens: /\((?<text>.+)\)/

  # Toggle problem list problem set
  toggleProblemSet: -> $(@problemSetTarget).val ''

  # Toggle problem list package
  togglePackage: ->
    if $(@problemSetTarget).val()
      package_text = $(@problemSetTarget).find('option:selected').text().match(@textInParens).groups.text
      $(@packageTarget).val $(@packageTarget).find("option:contains('#{package_text}')").val()

  # Toggle student list class
  toggleCourse: -> $(@courseTarget).val '' if @hasCourseTarget

  # Toggle student list school
  toggleSchool: ->
    if $(@courseTarget).val()
      school_text = $(@courseTarget).find('option:selected').text().match(@textInParens).groups.text
      $(@schoolTarget).val $(@schoolTarget).find("option:contains('#{school_text}')").val()

  # Toggle user list projects
  toggleProjects: (event) ->
    developer = /Administrator|Experimenter|Developer/.test $(event.target).find('option:selected').text()
    $(@projectsTarget).val [] unless developer
    $(@projectsTarget).prop 'disabled', not developer

  # Filter user projects so lower subprojects are disjoint from higher ones
  filterProjects: (event) ->
    if (current = $(@projectsTarget).val().find (value) => !$(@projectsTarget).prop('previous').includes(value))?
      $(@projectsTarget).val $(@projectsTarget).val().filter (value) =>
        value is current or not value.startsWith(current) and not current.startsWith(value)
    $(@projectsTarget).prop 'previous', $(@projectsTarget).val()

  # Select a folder and send path to search form
  selectFolder: (event) ->
    # console.log('selectFolder', event)
    deselect = event.ctrlKey || event.metaKey
    $(@formTarget).find('[id$="path"]').val if deselect then '' else $(event.target).data('path')
    $(event.target).closest('.table').find('.tr').removeClass('selected')
    $(event.target).closest('.tr').addClass('selected') unless deselect
    # event.stopPropagation()

  # Search tables with server request
  searchTable: (event) ->
    # console.log('searchTable', event)
    unless @hasSearchTarget and @searchTargets.includes event.target
      if @hasButtonTarget
        $(@buttonTarget).click()
      else
        Rails.fire(@formTarget, 'submit')
        # @formTarget.dispatchEvent(new Event 'submit', {bubbles: true, cancelable: true})

  # searchTable: (event) ->
  #   console.log('searchTable', event)
  #   klass = $(event.target).prop('id').split('_').pop()
  #   term = $(event.target).val()
  #   $(@tableTarget).find('.tr').each ->
  #     text = $(@).find(".td.#{klass}").text()
  #     console.log('term', term, 'text', text)
  #     $(@).toggleClass 'filtered', !!term && text != term
  #   @reshadeRows @tableTarget

  # Reset tables
  resetTable: (event) ->
    # console.log('resetTable', event)
    $filters = $(@formTarget).find('.filters')
    $filters.find('select').val -> $(@).find('option').val()
    $filters.find('input').val -> $(@).data('date') or null
    unless $filters.find('select').length then @filterTable() else @searchTable(event)
    event.preventDefault()

  # Filter tables locally based on search text
  filterTable: () ->
    # console.log('filterTable', event)
    if @hasSearchTarget
      terms = $(@searchTarget).val().toLowerCase().split(' ').compact()
      $(@tableTarget).find('.tr').each ->
        text = $(@).find('.td.filter').text().toLowerCase()
        $(@).toggleClass 'filtered', terms.some((term) -> not text.includes(term))
      @reshadeRows @tableTarget

  # Search reports with server request
  searchReports: -> Rails.fire(@controlsTarget, 'submit')

  # Update report controls
  updateControls: ->
    if @hasReportTarget and type = $(@reportTarget).val()
      text = if /student/.test(type) then 'Student' else 'Problem Set'
      sort = $(@controlsTarget).data('sort')[if text is 'Student' then 0 else 1]

      $(@controlsTarget).find('[id$="report_std_id"]').toggleClass 'hide', /student/.test(type)
      $(@controlsTarget).find('[id$="report_aps_id"]').toggleClass 'hide', type is 'progress' or /problem_set/.test(type)

      $(@controlsTarget).find('[id$="report_pr_sort_type"]').toggleClass 'hide', type is 'progress' or /skills/.test(type)
      $(@controlsTarget).find('[id$="report_pr_sort_type"] option').first().text("Sort by #{text}").val(sort) if /performance/.test(type)

      $(@controlsTarget).find('[id$="report_sk_group_type"]').toggleClass 'hide', type is 'progress' or /performance/.test(type)
      $(@controlsTarget).find('[id$="report_sk_sort_type"]').toggleClass 'hide', type is 'progress' or /performance/.test(type)
      $(@controlsTarget).find('[id$="report_sk_group_type"] option').first().text("Group by #{text}").val(sort) if /skills/.test(type)
