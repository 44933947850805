import ApplicationController from './application_controller'

export default class extends ApplicationController
  @values = {subdirs: Object}
  @targets: ['table', 'path', 'file', 'progress', 'submit']

  # Confirm package load over 10 count
  confirm: (event) ->
    count = $(@tableTarget).find('.td.icon input[type="checkbox"]:checked').length
    count <= 10 or confirm("Are you sure you want to load #{count} packages?") or event.preventDefault()

  # Select files to upload
  select: (event) -> $(@fileTarget).click(); event.preventDefault()

  # Upload asset files or zipped packages
  load: (event) ->
    if $(@fileTarget).data('prompt')
      components = $(@fileTarget).prop('files')[0].name.split('.')
      type = components.slice(-1)[0]; subtype = components.slice(-2).join('.')
      $(@fileTarget).data('prompt')['default'] = @subdirsValue[subtype] || @subdirsValue[type] || @subdirsValue['asset']
      $(@fileTarget).attr 'data-prompt', JSON.stringify $(@fileTarget).data('prompt')
      return event.preventDefault() unless Rails.handlePrompt.call $(@fileTarget).get(0), event

    $(@pathTarget).val new URLSearchParams($(@fileTarget).attr 'data-params').get('asset_path') if @hasPathTarget

    $.post(
      url: $(@submitTarget).attr('formaction') || $(@element).attr('action'), dataType: 'script',
      data: new FormData(@element), contentType: false, processData: false,
      xhr: (->
        if (myXhr = $.ajaxSettings.xhr()).upload
          $(myXhr.upload).
            on('loadstart', -> $(@progressTarget).toggleClass('hidden')).
            on('loadend', -> setTimeout((-> $(@progressTarget).toggleClass('hidden')), 100)).
            on('progress', (event) ->
              if event.originalEvent.lengthComputable
                $(@progressTarget).prop(value: event.originalEvent.loaded, max: event.originalEvent.total))
        myXhr))

    event.preventDefault()
