import ApplicationController from './application_controller'

export default class extends ApplicationController

  # Passing static data to dashboards
  initialize:  ->
    $dashboard = $(@element)
    $(window).on 'message', (event) ->
      if event.originalEvent.origin is window.location.origin
        if event.originalEvent.data['id'] in ['teacher_dashboard', 'class_dashboard', 'student_dashboard'] and
           event.originalEvent.data['command'] is 'ready'
          console.log '[Dashboard] Received message from dashboard ', event.originalEvent.data
          parameters = Object.camelizeEntries $dashboard.data('dashboard')
          console.log '[Dashboard] Sending static parameters to dashboard ', parameters
          $dashboard.prop('contentWindow').postMessage parameters, window.location.origin
