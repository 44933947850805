import ApplicationController from './application_controller'

export default class extends ApplicationController

  # Table row reshading
  reshadeRows: (table) ->
    $rows = $(table).find('.tr:not(.hide):not(.filtered)')
    $rows.odd().removeClass('row_odd').addClass('row_even')
    $rows.even().removeClass('row_even').addClass('row_odd')

  # Color resetting in add list commands
  setColorTab: (event) ->
    ($target = $(event.target)).removeClass(['errors', 'oks'])
    if event.key is 'Tab' and event.ctrlKey and event.altKey
      event.preventDefault()
      start = $target.prop 'selectionStart'; end = $target.prop 'selectionEnd'
      $target.val $target.val().substring(0, start) + "\t" + $target.val().substring(end)
      $target.prop selectionStart: start + 1, selectionEnd: start + 1
