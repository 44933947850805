import ApplicationController from './application_controller'

export default class extends ApplicationController
  @values = {subdirs: Object}
  @targets: ['command', 'category', 'problemFile', 'problemFileUpload', 'studentInterface', 'studentInterfaceUpload',
             'assetName', 'assetPath', 'consumer', 'provider', 'projects', 'school']

  # Store initial value of projects selector
  initialize: ->
    $(@projectsTarget).prop 'previous', $(@projectsTarget).val() if @hasProjectsTarget

  # Select input boxes on focus
  selectOnFocus: (event) -> $(event.target).select()

  # Toggle selection command field for problem sets
  toggleCommand: (event) ->
    value = $(event.target).val()
    $(@commandTarget).prop 'disabled', not (value in ['remote_selection', 'external_selection', 'local_selection'])
    $(@commandTarget).val '' unless value in ['remote_selection', 'external_selection', 'local_selection']

  # Toggle category and parent field for categories
  toggleCategory: (event) ->
    value = $(event.target).val()
    $(@categoryTargets).prop('disabled', value isnt 'standards').
      val (i) => if value is 'standards' and i is 0 then 'standard' else ''

  # Toggle file fields for problems
  toggleProblemFileUpload: (event) -> $(@problemFileUploadTarget).val '' if $(event.target).val()
  toggleProblemFile: (event) -> $(@problemFileTarget).val '' if $(event.target).val()
  toggleStudentInterfaceUpload: (event) -> $(@studentInterfaceUploadTarget).val '' if $(event.target).val()
  toggleStudentInterface: (event) -> $(@studentInterfaceTarget).val '' if $(event.target).val()

  # Fill file fields for assets
  fillAssetFields: (event) ->
    if value = $(event.target).val()
      name = value.split('\\')[2]; components = name.split('.')
      type = components.slice(-1)[0]; subtype = components.slice(-2).join('.')
      $(@assetNameTarget).val name
      $(@assetPathTarget).val @subdirsValue[subtype] || @subdirsValue[type] || @subdirsValue['asset']

  # Toggle consumer & provider fields for schools
  toggleConsumer: (event) -> $(@consumerTargets).prop 'disabled', $(event.target).prop 'checked'
  toggleProvider: (event) -> $(@providerTargets).prop 'disabled', $(event.target).prop 'checked'

  # Toggle user projects
  toggleProjects: (event) ->
    developer = /Administrator|Experimenter|Developer/.test $(event.target).find('option:selected').text()
    $(@projectsTarget).val [] unless developer
    $(@projectsTarget).prop 'disabled', not developer

  # Filter user projects so lower subprojects are disjoint from higher ones
  filterProjects: (event) ->
    if (current = $(@projectsTarget).val().find (value) => !$(@projectsTarget).prop('previous').includes(value))?
      $(@projectsTarget).val $(@projectsTarget).val().filter (value) =>
        value is current or not value.startsWith(current) and not current.startsWith(value)
    $(@projectsTarget).prop 'previous', $(@projectsTarget).val()

  # Toggle schools for contact
  toggleSchool: (event) ->
    teacher = /Teacher/.test $(event.target).find('option:selected').text()
    $(@schoolTargets).val '' unless teacher
    $(@schoolTargets).prop 'disabled', not teacher

  # # fix width for field_with_errors
  # $ -> $('.field_with_errors').not(':has(label)').css('width', () -> $(@).children().css('width')).children().css('width', '100%')
