import ApplicationController from './application_controller'

export default class extends ApplicationController
  @targets: ['headCheck', 'rowCheck']

  # Row checkbox toggling
  toggleRows: (event) ->
    $visibleChecks = $(@rowCheckTargets).filter -> $(@).closest('.tr').filter(':not(.filtered').length
    $visibleChecks.prop 'checked', $(event.target).prop('checked')

  # Head checkbox toggling
  toggleHead: (event) ->
    $visibleChecks = $(@rowCheckTargets).filter -> $(@).closest('.tr').filter(':not(.filtered').length
    $(@headCheckTarget).prop 'checked', not $visibleChecks.filter(':not(:checked)').length

  # Hierarchical checkbox toggling
  toggleTree: (event) -> @toggleSubtree event.target, $(event.target).prop('checked')

  toggleSubtree: (currentCheck, up) ->
    $currentRow = $(currentCheck).closest('.tr')
    selector = if up then ".tr##{$currentRow.data('parent')}" else ".tr[data-parent=\"#{$currentRow.prop('id')}\"]"
    $nextChecks = $(@rowCheckTargets).filter -> $(@).closest(selector).length
    $nextChecks.each (_, nextCheck) => $(nextCheck).prop 'checked', up; @toggleSubtree nextCheck, up
