import ApplicationController from './application_controller'

export default class extends ApplicationController
  @values: {path: String, planets: Boolean}
  @targets: ['header', 'button', 'main', 'modal', 'dialog', 'astro', 'login', 'reset', 'interest']

  # Open login dialog on page load
  connect: -> $(@buttonTarget).click() if $(@modalTarget).hasClass('visible')

  # Toggle login dialog on button click
  toggleLogin: (event) ->
    $.ajax url: @pathValue, type: 'GET', data: $.param({login: not $(@modalTarget).is(':visible')})
    $(@modalTarget).slideToggle(200)
    $(@mainTarget).toggleClass('blurred') if @hasPlanetsValue
    @setLoginOffset() unless @hasPlanetsValue
    event.stopPropagation()

  # Hide login dialog on window click
  hideLogin: (event) ->
    if $(@modalTarget).is(':visible') and ($(event.target).prop('id') is 'close' or not $(event.target).closest(@dialogTarget).length)
      $.ajax url: @pathValue, type: 'GET', data: $.param({login: false})
      $(@modalTarget).slideUp(200)
      $(@mainTarget).removeClass('blurred') if @planetsValue
      $(@astroTarget).slideUp(200) if @planetsValue

  # Show username reminder
  remindUsername: (event) -> $(@astroTarget).slideDown(200); event.preventDefault()

  # Show reset pasword panel
  resetPassword: (event) ->
    $.ajax url: @pathValue, type: 'GET', data: $.param({reset: true})
    $(@astroTarget).slideUp(200)
    $(@loginTarget).hide(); $(@resetTarget).show()
    event.preventDefault()

  # Show contact us panel
  contactUs: (event) ->
    $.ajax url: @pathValue, type: 'GET', data: $.param({contact: true})
    $(@astroTarget).slideUp(200)
    $(@loginTarget).hide(); $(@interestTarget).show()
    event.preventDefault()

  # Go back to login panel
  back: (event) ->
    $.ajax url: @pathValue, type: 'GET', data: $.param({reset: false, contact: false})
    $(@resetTarget).hide(); $(@interestTarget).hide(); $(@loginTarget).show()
    event.preventDefault()

  # Reposition login modal
  setLoginOffset: ->
    $next = $(@headerTarget).next(':visible'); height = $(window).height()
    header_top = $(@headerTarget).offset().top; next_top = if $next.length then $next.offset().top else height
    [setting, offset] = if next_top < height / 2 then ['top', next_top] else ['bottom', height - header_top]
    $(@modalTarget).css({top: 'unset', bottom: 'unset'}).css(setting, offset)
