import consumer from './consumer'

$ ->
  if (team_id = $('#tutor_container').data('team_id')) and (student_assignment_id = $('#tutor_container').data('student_assignment_id'))
    team_channel = consumer.subscriptions.create {channel: 'TeamChannel', team_id, student_assignment_id},
      subscribed: false; tutor: null; tutorInitialized: false; currentName: null
      serverMessages: []; tutorMessages: []

      connected: ->
        ActionCable.logger.log 'Connected'
        while message = @serverMessages.shift()
          ActionCable.logger.log 'Sending buffered message to server', message
          @send message
        @subscribed = true

      disconnected: ->
        ActionCable.logger.log 'Disconnected'
        @subscribed = false

      received: (data) ->
        ActionCable.logger.log 'Received message from server', data
        if data.command in ['subscribed', 'unsubscribed']
          @currentName ?= data.display_name
          @updateUserCount(data)
        if @tutorInitialized
          ActionCable.logger.log 'Sending unbuffered message to tutor', data
          @tutor.postMessage data, window.location.origin
        else
          @tutorMessages.push data

      receivedFromTutor: (message) ->
        @tutor = $('#interface').prop('contentWindow')
        ActionCable.logger.log 'Received message from tutor', message
        if message['command'] is 'tutorInitialized'
          while bufferedMessage = @tutorMessages.shift()
            ActionCable.logger.log 'Sending buffered message to tutor', bufferedMessage
            @tutor.postMessage bufferedMessage, window.location.origin
          @tutorInitialized = true
        else if @subscribed
          ActionCable.logger.log 'Sending unbuffered message to server', message
          @send message
        else
          @serverMessages.push message

      updateUserCount: (data) ->
        displayNames = data.team_members.map((identity) => identity.split('/')[1])
        displayNames.splice displayNames.indexOf(@currentName), 1
        displayNames.unshift @currentName + ' (you)'
        ($count = $('button#count')).attr('title', displayNames.join(', ')).find('span').text(data.team_count)
        $count.addClass('tooltip')
        setTimeout (-> $count.removeClass('tooltip')), 3000

    $(window).on 'message', (event) ->
      if event.originalEvent.origin is window.location.origin and event.originalEvent.data['id'] is team_id
        team_channel.receivedFromTutor event.originalEvent.data
